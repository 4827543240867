<!--    机构组管理-->
<template>
    <div class="DataDisplay" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="省份">
                            <el-select v-model="searchProvinceCode" placeholder="请选择">
                                <el-option
                                    v-for="item in selectProvince"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="城市">
                            <el-select v-model="searchCityCode" placeholder="请选择">
                                <el-option
                                    v-for="item in selectCity"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
            <el-button
                type="success"
                @click="handleShowDataDisplayNationwide"
                size="small"
                style="margin-left: 20px"
                v-if="hasPrivilege('biz.report.datadsp.nation.query')"
                >全国数据大屏
            </el-button>
            <el-button
                type="success"
                @click="handleShowDataDisplayLangqin"
                size="small"
                style="margin-left: 20px"
                v-if="hasPrivilege('biz.report.datadsp.lqxx.query')"
                >朗勤心选数据大屏
            </el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                width="1500"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" type="index" width="90" align="center" />
                <el-table-column label="省份" width="220" prop="provinceName" />
                <el-table-column label="城市" width="280" prop="cityName" />
                <el-table-column label="门店数量" width="150" prop="storeCount" />
                <el-table-column label="操作" min-width="120" header-align="center" prop="operate" key="operate">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleShowDataDisplay(scope.row)"
                            style="margin-left: 80px"
                            v-if="hasPrivilege('biz.report.datadsp.query')"
                            >数据大屏显示
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleShowDataMonthDisplay(scope.row)"
                            style="margin-left: 80px"
                            v-if="hasPrivilege('biz.report.datadsp.month.query')"
                            >月度数据大屏显示
                        </el-button>
                        <!--                        注释掉datav大屏显示-->
                        <!--
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleShowDataMonthDisplayForDatav(scope.row)"
                            style="margin-left: 80px"
                            v-if="hasPrivilege('biz.report.datadsp.month.query')"
                            >datav城市大屏显示
                        </el-button>
-->
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';

export default {
    name: 'DataDisplay',
    mixins: [CheckTableShowColumn],

    data() {
        return {
            selectProvince: [], //省
            selectCity: [], //市
            searchCityCode: '', //查询市
            searchProvinceCode: '', //查询省
            tableData: [],
            url: {
                queryManagementCity: '/report/dataDisplay/queryManagementCity',
                queryManagementProvinceCity: '/report/dataDisplay/queryManagementProvinceCity',
            },
        };
    },
    watch: {
        searchProvinceCode(val) {
            //省改变  就改变市
            if (this.searchProvinceCode) {
                //有省 就查市
                Util.queryTable(
                    this,
                    this.url.queryManagementProvinceCity,
                    { params: { provinceCode: val } },
                    (res) => {
                        this.selectCity = res.data;
                        this.searchCityCode = null;
                        this.selectCity.unshift({ name: '请选择', code: '' });
                    }
                );
            } else {
                //没有省 清空市
                this.selectCity = [];
                this.searchCityCode = null;
                this.selectCity.unshift({ name: '请选择', code: '' });
            }
        },
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },

    methods: {
        handleQuery() {
            const params = {
                params: {
                    searchCityCode: this.searchCityCode,
                    searchProvinceCode: this.searchProvinceCode,
                },
            };
            Util.queryTable(this, this.url.queryManagementCity, params);

            Util.queryTable(this, this.url.queryManagementProvinceCity, null, (res) => {
                this.selectProvince = res.data;
                this.selectProvince.unshift({ name: '请选择', code: '' });
            });
        },
        handleShowDataDisplay(row) {
            //跳转数据大屏页面
            window.open('/datadisplay/DataDisplay.html?cityCode=' + row.cityCode);
        },
        handleShowDataMonthDisplay(row) {
            //跳转数据大屏页面
            window.open('/datadisplay/CityMonthDataDisplay.html?cityCode=' + row.cityCode);
        },
        handleShowDataMonthDisplayForDatav(row) {
            //跳转数据大屏页面
            window.open('/datadisplay/blank.html?citypartnerCityCode=' + row.cityCode);
        },
        handleShowDataDisplayNationwide() {
            //跳转数据大屏页面
            window.open('/datadisplay/DataDisplayNationwide.html');
        },
        handleShowDataDisplayLangqin() {
            // 跳转朗勤心选数据大屏页面
            window.open('/datadisplay/DataDisplayLangqin.html');
        },
    },
};
</script>

<style scoped>
.DeptGroup .el-form-item {
    margin-bottom: 0;
}
</style>
